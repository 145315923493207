/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
}

.mat-card {
  border-radius: 0 !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  border-top: 5px solid #78909c !important;
}

/*
  NGX Notify Service
*/

$white: #fff;

.default-notification-overlay {
  color: $white;
  background-color: #455a64;
}

.error-notification-overlay {
  color: $white;
  background-color: #ff0000;
}

.info-notification-overlay {
  color: $white;
  background-color: #0099cc;
}

.success-notification-overlay {
  color: $white;
  background-color: #007e33;
}

.warning-notification-overlay {
  color: $white;
  background-color: #ff8800;
}

.transparent {
  opacity: 0.8;
}

.mat-row:hover {
  background-color: #fafafa;
}

/* Formly Material */
.display-flex { display: flex; }

.flex-1 { flex: 1; }

.flex-2 { flex: 2; }

.flex-3 { flex: 3; }

.flex-4 { flex: 4; }

.flex-5 { flex: 5; }

.flex-6 { flex: 6; }

[class*="flex-"] {
  padding-left: 10px;
  padding-right: 10px;
}

[class*="flex-"]:first-child {
  padding-left: 0;
}

[class*="flex-"]:nth-last-child(2) {
  padding-right: 0;
}

.textarea-large .mat-form-field-flex {
  min-height: 200px;
}

.form-section {
  font-size: 16px;
  font-weight: 500;
  margin: 15px 15px 20px 0;
  width: 100%;
  padding: 10px;
  background: #253746;
  color: #fff;
}

.mat-form-field-required-marker {
  color: red !important;
}

.ql-editor { 
  background-color: rgba(0, 0, 0, 0.04);
  min-height: 200px;
}
